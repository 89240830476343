.video-operation {
    width: 200%;
    white-space: nowrap;
}

.video-operation-input {
    display: grid;
    grid-template-columns: 200px min-content;
}

.dropdown-menu {
  height: 300px;
  overflow-y: scroll;
}