html {
    scroll-snap-type: y mandatory;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.landing-section {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    scroll-snap-align: start;
}

.landing-section-content {
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.landing-section-content h1, .landing-section-content h5 {
    font-weight: bold;
    color: #FF005C;
}

.landing-section-content h1 {
    font-size: 4rem;
}

.landing-section-content h3 {
    color: #FF005C;
    font-weight: bolder;
    font-size: 2rem;
    line-height: 1.44;
}

.landing-section-content h5 {
    font-size: 1.6rem;
}

.landing-section-content p {
    font-size: 1.1rem;
}

@media only screen and (max-width: 700px) {
    .landing-section-content {
        margin-left: 3%;
        margin-right: 3%;
    }

    .landing-section-content h1, h5 {
        font-weight: bold;
        color: #FF005C;
    }

    .landing-section-content h1 {
        font-size: 1.6rem;
    }

    .landing-section-content h3 {
        font-size: 1.1rem;
    }

    .landing-section-content h5 {
        font-size: 0.4rem;
    }

    .landing-section-content p {
        font-size: 0.8rem;
    }
}

