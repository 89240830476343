#video-player-interactive {
    display: block;
    margin: 0 auto;
    width: auto;
    height: calc(50vh - 5px);
}

.interactive-report-frv {
    text-align: center;
    overflow-y: scroll;
}

.interactive-report-frv div {
    padding: 5px;
}

.interactive-report-video-buttons {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-areas: ". interactive-buttons .";
}

#interactive-buttons {
    grid-area: interactive-buttons;
    padding: 5px;
}

@media screen and (max-width: 700px) {
    #video-player-interactive {
        height: auto;
        width: 100vw;
    }

    #interactive-report-frv-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .interactive-report-frv h2 {
        font-size: 1.2rem;
    }
}