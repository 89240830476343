.add-operation {
    width: 50%;
    margin: 10px auto 0;
}

.operation-name {
    float: left;
    width: 90%;
}

.ok-button {
    float: right;
    width: 10%;
}

.existing-frames-container {
    margin: 0 auto;
    float: left;
    width: 100%;
}