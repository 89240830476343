.text-red {
    color: #FF005C;
}

#services-text p {
    font-size: 1.0rem;
}

#services-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

#services-left {
    line-height: 2rem;
}

#services-left hr {
    border-top: 4px solid #000000;
}

#services-left h5 {
    color: black;
}