.app-navbar {
    width: 100%;
    top: 0;
    z-index: 99999;
}

#nav-abs {
    position: absolute;
}

#logo {
    height: auto;
    width: auto;
    max-height: 50px;
}

.app-navbar h4 {
    font-size: 14px;
}

.app-navbar a {
    font-size: 14px;
    line-height: 1;
}