#sure-content {
    text-align: left;
}

#sure-content-title, #sure-mission {
    margin-bottom: 2.5rem;
}

#sure-mission h1 {
    color: black;
}

#sure-content-title h3 {
    font-size: 1.5rem;
}

#sure-content h6 {
    color: gray;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
}

#sure-content p {
    font-size: 1.3rem;
}

@media only screen and (max-width: 700px) {
    #sure-content-title, #sure-content-subtitle {
        margin-bottom: 0rem;
    }

    #sure-content h6 {
        font-size: 0.8rem;
    }

    #sure-content p {
        font-size: 1.0rem;
    }
}
