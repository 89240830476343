.new-frame {
    margin-top: 10px;
}

.field-container {
    display: flex;
    width: 90%;
    margin: 0 auto;
}


.frames {
    flex-grow: 0.1;
}

