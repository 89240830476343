#what-we-got h1 {
    text-align: center;
    margin-bottom: 1.5rem;
}

#what-we-got h2 {
    margin-bottom: .1rem;
    color: #FF005C;
    font-weight: bolder;
    font-size: 30px;
    line-height: 1.22;
}

#what-we-got .what-we-got-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

#what-we-got .what-we-got-card-container .what-we-got-card {
    padding: 5px;
    margin: 1px;
    text-align: center;
}

/*#what-we-got .what-we-got-card-container .what-we-got-card p {*/
/*    text-align: left;*/
/*}*/

@media only screen and (max-width: 700px) {
    #what-we-got {
        display: none;
    }
}
