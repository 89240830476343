@import '~material-design-icons/iconfont/material-icons.css';
@import '~sal.js/dist/sal.css';

footer {
    bottom: 0;
    font-size: 12px;
    left: 0;
    padding: 15px 0;
    position: fixed;
    text-align: center;
    width: 100%;
}

body {
    font-size: 0;
}

body > * {
    font-size: 16px;
}

.model-div,.ui-console{
  float:left;
}

html,
body,
header,
#root,
.labracv {
    height: 100%;
}

table {
    table-layout: fixed;
    width: 100%;
}

td {
    word-wrap: break-word;
    hyphens: auto;
}

.page {
    position: relative;
    overflow: hidden;
    page-break-after: always;
}

