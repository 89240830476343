.projects-data-buttons-button a:hover {
    color: #fff;
}

.projects {
    display: grid;
    grid-template-columns: 360px 1fr;
}

.projects-unselected {
    padding: 10px 10px 0;
}

.projects-unselected-buttons {
    display: grid;
    grid-template-columns: min-content min-content min-content min-content;
    justify-content: center;
}

.projects-data {
    padding: 0 10px 10px;
}

.projects-data-buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas: "projects-data-buttons-top" "projects-data-buttons-mid" "projects-data-buttons-bot";
    /*grid-template-columns: min-content min-content 200px 200px min-content min-content min-content*/
}

.projects-data-buttons .projects-data-buttons-top {
    grid-area: projects-data-buttons-top;
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: center;
}

.projects-data-buttons .projects-data-buttons-mid {
    grid-area: projects-data-buttons-mid;
    display: grid;
    grid-template-columns: 300px 200px 200px;
    justify-content: center;
}

.projects-data-buttons .projects-data-buttons-bot {
    grid-area: projects-data-buttons-bot;
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: center;
}

.projects-data-buttons-button {
    padding: 5px;
}

.project-data-operations-data-element-form {
    padding: 5px;
    display: flex;
    align-items: flex-end;
}

.project-data-operations-data-element {
    display: grid;
    grid-template-columns: 200px 1fr 1fr min-content;
}

.project-data-operations-add-button {
    padding: 5px;
}

.project-data-operations-save-button {
    padding: 5px;
}

.projects-steps-generation {
    text-align: center;
}

.projects-data-report-generator-buttons {
    padding: 5px;
}


.projects-steps-parameters-card {
    height: min-content;
    width: auto;
    padding: 10px;
}

.projects-steps-parameters-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.projects-steps-shift-container {
    margin-bottom: 5px;
}

.projects-steps-shift-add {
    padding: 5px;
    display: flex;
    justify-content: center;
}

.project-steps-parameters-shifts-start-end {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr min-content;
    column-gap: 5px;
}

.projects-steps-parameters-save {
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.projects-steps-parameters {
    margin-top: 10px;
}
