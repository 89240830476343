.markup-content {
    display: grid;
    grid-template-rows: min-content min-content min-content;
    grid-template-columns: 15% 1fr 15%;
    grid-template-areas: ". reports-search ." ". reports-table ." ". pagination .";
}

.markup-search {
    grid-area: reports-search;
    display: grid;
    grid-template-columns: max-content max-content;
    margin-bottom: 5px;
    margin-top: 5px;
}

.markup-search div {
    margin-right: 5px;
}

.markup-table {
    grid-area: reports-table;
}

.pagination {
    grid-area: pagination;
    margin-left: auto;
    margin-right: auto;
}