.feedback-fields {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.feedback-button {
    margin-top: 5px;
}

.phone-number {
    margin-top: 10px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: #757575;
}

.recaptcha {
    margin-top: 10px;
}

.result {
    margin-top: 50px;
    margin-bottom: 50px;
}

.recaptcha p {
    color: grey;
    font-size: 0.5rem
}

@media only screen and (max-width: 700px) {
    .feedback-fields {
        width: 100%;
    }
}
