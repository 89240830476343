.video {
    display: grid;
    grid-template-columns: 3% 1fr 3%;
    grid-template-rows: min-content min-content min-content min-content min-content min-content min-content min-content;
    grid-template-areas: ". video-filename ." ". video-player-container ." ". video-player-parameters ." ". save-button-top ." ". video-buttons ." ". video-options ." ". alert ." ". save-button .";
}

.video div, video {
    margin-left: auto;
    margin-right: auto;
}


#video-player-container {
    grid-area: video-player-container;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    grid-template-columns: min-content 1fr;
    grid-template-areas: "video-player video-player-other" "video-player video-player-last" "video-player current-operation";
    margin-left: 2%;
    margin-right: 2%;
}

#video-player-container td {
    font-size: 10px;
}

#video-player-container p {
    font-size: 12px;
}

#video-player-container button {
    font-size: 12px;
}


#current-operation {
    grid-area: current-operation;
}

#video-player {
    grid-area: video-player;
    margin-right: 20px;
    margin-left: 1rem;
}

.video-filename {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    grid-area: video-filename;
}

.video-player-parameters {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    grid-area: video-player-parameters;
    display: grid;
    grid-template-columns: max-content max-content;
}

.video-player-parameters div {
    margin-right: 5px;
}

.video-buttons {
    grid-area: video-buttons;
    display: grid;
    grid-template-columns: min-content min-content min-content max-content;
}

.video-buttons .video-button {
    margin: 5px;
}

.video-options {
    grid-area: video-options;
    display: grid;
    grid-template-columns: min-content 1fr min-content min-content auto auto 1fr min-content;
    grid-auto-rows: min-content;
}

.video-options div {
    margin-left: 5px;
    margin-right: 5px;
}

.video-button-save {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#top {
    grid-area: save-button-top;
}

#bot {
    grid-area: save-button;
}

.video-button-save div {
    padding: 5px
}

.alert {
    grid-area: alert;
    margin-left: auto;
    margin-right: auto;
}

