#who-we-are-top {
    display: grid;
    grid-template-columns: 1fr min-content;
}

#who-we-are-top-text b {
    font-size: 1.2rem;
}

#who-we-are-top {
    padding: 50px;
}

#who-we-are-top-card img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

#who-we-are-top-card {
    text-align: center;
}

#who-we-are-top-card b {
    white-space: nowrap;
}

#effective-platform {
    text-align: center;
}

#effective-platform p {
    padding-left: 300px;
    padding-right: 300px;
}


#cases h3 {
    text-align: center;
    margin-bottom: 50px;
}

#cases-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(330px, 1fr));
    gap: 10px;
}

#cases-cards h4 {
    color: #FF005C;
    margin-top: 30px;
    margin-bottom: 20px;
}

.metrics-cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(250px, 1fr));
    gap: 20px;
}

#metrics h3 {
    text-align: center;
    margin-bottom: 40px;
}

.metrics-cards h4 {
    color: #FF005C;
    text-align: center;
}

.circle {
    background: black;
    color: #fff;
    padding: 15px 25px;
    border-radius: 50%;
    font-size: 25px;
}

#metrics p {
    font-size: 1rem;
    /*text-align: justify;*/
}

.metrics-cards div {
    display: grid;
    grid-template-rows: 180px min-content;
}

#metrics-cards-top div {
    grid-template-rows: 100px min-content;
}