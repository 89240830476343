.news-content-cards {
    display: grid;
    grid-template-columns: repeat(4, 270px);
    gap: 10px;
    align-content: space-between;
    justify-content: space-around;
}

#news-content h1 {
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    color: black;
    margin-bottom: 20px;
}

.news-card {
    display: grid;
}

.news-card p {
    font-size: 14px;
}

.news-card h3 {
    margin-top: 10px;
    color: #FF005C;
    font-weight: normal;
    word-wrap: break-word;
    width: 100%;
    font-size: 18px;
    line-height: 1;
}

.news-card .img-container {
    width: 260px;
    height: 240px;
}

.news-card img {
    object-fit: cover;
    width: 260px;
    height: 240px;
}