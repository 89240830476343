.leaflet-pm-icon-bin {
    background-image: url('../img/icons/bin-icon.png') !important;
}

.leaflet-pm-icon-optimise {
    background-image: url('../img/icons/optimise.png') !important;
}

.leaflet-pm-icon-undo {
    background-image: url('../img/icons/undo-icon.png') !important;
}

.leaflet-pm-icon-redo {
    background-image: url('../img/icons/redo-icon.png') !important;
}

.leaflet-pm-icon-menu {
    background-image: url('../img/icons/menu.png') !important;
}

.leaflet-pm-icon-house {
    background-image: url('../img/icons/house.png') !important;
}

.leaflet-pm-icon-generate {
    background-image: url('../img/icons/generate.png') !important;
}

.leaflet-popup-content-wrapper {
    width: fit-content;
}

.leaflet-area-label-marker {
    background-color: #fefdcb;
    border: 3px solid black;
    border-radius: 0;
    color: black;
    font-weight: 500;
    opacity: 1;
    padding: 3px;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-align: center;
}

.info h2 {
    margin: 0 0 5px;
    color: black;
    font: 14px/16px Arial, Helvetica, sans-serif;
    text-align: left;
}

.info h3 {
    margin: 0 0 5px;
    color: #777;
    font: 17px/18px Arial, Helvetica, sans-serif;
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    min-height: 80%;
}

.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}
