.edit {
    display: grid;
    margin-left: 7%;
    margin-right: 7%;
}

.edit-header {
    text-align: center;
}

.edit-buttons {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: min-content min-content;
}

.edit-buttons div {
    padding: 5px;
}

.edit-markups {
    display: grid;
    grid-template-columns: min-content min-content min-content auto auto 1fr min-content;
    grid-auto-rows: min-content;
}

.edit-markups div {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}