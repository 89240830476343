.webp #new-word {
    background-image: url("../../img/background.webp");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
}

.no-webp #new-word {
    background-image: url("../../img/background.png");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
}

#new-word-content {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#new-word-buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 10px;
}

#new-word h1, #new-word h2 {
    margin-bottom: .5rem;
    line-height: 1.44;
    /*letter-spacing: 0.1em;*/
}

#new-word h1 {
    font-size: 4rem;
    font-weight: bolder;
    letter-spacing: 0;
    text-align: center;
}

#new-word h2 {
    font-size: 1.2rem;
}

@media only screen and (max-width: 700px) {
    #new-word-content {
        margin-left: 4%;
        margin-right: 4%;
    }

    #new-word h1, #new-word h2 {
        margin-bottom: .5rem;
        letter-spacing: 0.1em;
    }

    #new-word h1 {
        font-size: 1.5rem;
    }

    #new-word h2 {
        font-size: 0.9rem;
    }
}