#reviews .reviews-card-container {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

#reviews .reviews-card-container .reviews-card {
    padding: 10px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
}

#reviews h1 {
    text-align: center;
}

#reviews .reviews-card-container img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

@media only screen and (max-width: 700px) {
    #reviews .reviews-card-container {
        padding: 2px;
        grid-template-columns: 1fr 1fr;
    }

    #reviews .reviews-card-container .reviews-card {
        padding: 2px;
        width: 70px;
        height: 70px;
    }
}