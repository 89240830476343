@import url('https://fonts.googleapis.com/css?family=Montserrat:500&display=swap');

.statement {
    margin-left: 3%;
    margin-right: 3%;
}

@media print {
    .statement {
        margin-left: 0;
        margin-right: 0;
    }

    .pdf-button {
        visibility: hidden;
    }
}

@page {
    margin: 20mm 10mm 20mm 30mm;
}

.statement div {
    margin-top: 5px;
    font-size: 0.7rem;
}

.statement h1 {
    font-size: 1.75rem;
}

.statement h2 {
    font-size: 1.4rem;
}

.statement h4 {
    font-size: 1.05rem;
}

.statement h5 {
    font-size: 0.875rem;
}

.statement h6 {
    font-size: 0.7rem;
}

.statement #mes-img {
    grid-area: statement-mes-img;
    margin-left: auto;
    margin-right: auto;
}

.statement #mes-img h2 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.statement #mes-img img {
    width: 90%;
    height: auto;
    padding: 5px;
}

.statement th {
    text-align: center;
}

.statement-header {
    margin-left: auto;
    margin-right: auto;
}

.statement-measurements #mes-img, img {
    margin: 0 auto;
}
