#form-content {
    top: 50%;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.cameras-section {
    display: grid;
    grid-template-columns: 1fr;
}

.cameras-section p {
    font-size: xx-large;
    font-weight: bold;
}

.cameras-section img {
    max-width: 70%;
}