#footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 99999;
    text-align: center;
    background: black;
    margin: 0;
}

#footer .footer-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas: "footer-text footer-text" "elibrary scholar" "copyright copyright";
}

#footer .footer-links-img {
    height: 75px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}

#footer .footer-text {
    grid-area: footer-text;
}

#footer #footer-elibrary {
    grid-area: elibrary;
}

#footer #footer-scholar {
    grid-area: scholar;
}

#footer-copyright {
    grid-area: copyright;
}

#footer .footer-links img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: grayscale(1) contrast(1.5);
}

/*#footer-copyright {*/
/*    grid-area: copyright;*/
/*}*/

/*#footer-copyright h5 {*/
/*    color: white;*/
/*}*/

/*@media only screen and (max-width: 700px) {*/
/*    #footer h5 {*/
/*        font-size: 14px;*/
/*    }*/

/*    #footer .footer-links {*/
/*        margin-right: 4%;*/
/*        margin-left: 4%;*/
/*        display: grid;*/
/*        grid-template-columns: 1fr;*/
/*        grid-template-rows: min-content min-content min-content min-content;*/
/*        grid-template-areas: "footer-text" "elibrary" "scholar" "copyright";*/
/*    }*/
/*}*/
