.calculator h1 {
    text-align: center;
}

.calculator-paper-container {
    padding: 16px;
    hyphens: auto;
    /*max-width: 50vw;*/
}

.calculator-required-measurements {
    padding: 10px;
}

#calculator-table {
    padding: 0
}

.calculator-form-group {
    padding: 10px;
}

.calculator-blocks {
    display: grid;
    grid-template-columns: repeat(2, 50%);
}

.calculator-graph {
    height: max-content;
}

.calculator-graph-chart {
    height: 500px;
}