.registration {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-areas: ". registration-form .";
}

.registration .registration-form {
    grid-area: registration-form;
}

.registration .registration-form .registration-input-group{
    margin-bottom: 10px;
}
