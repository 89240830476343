.login {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-areas: ". auth-form .";
}

.login .auth-form {
    grid-area: auth-form;
}

.login .auth-form .login-group{
    margin-bottom: 10px;
}

.login-buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 10px;
}

