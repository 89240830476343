#form {
    position: relative;
}

#form-begin {
    position: relative;
    margin-top: 10%;
}

#form-content {
    top: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form-container {
    padding: 10px;
}

@media only screen and (max-width: 700px) {
    #footer {
        display: none;
    }

    #form-content {
        top: 50%;
        display: block;
    }

    .form-container {
        padding: 0;
    }
}
