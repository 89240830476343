.video-markup {
    position: relative;
}

.list-operations {
    float: left;
}

.add-button {
    margin-right: 5px;
}

.date-container {
    width: 50%;
    display: flex;
    margin: 0 auto;
}

.date-picker {
    flex-grow: 0.1;
}


