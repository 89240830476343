.marking-page {
    /*вынести в app.css*/
    margin-left: 3%;
    margin-right: 3%;
}

.marking-page-element {
    padding: 5px;
    margin: 0 auto;
}

#video-players {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    justify-items: center;
    align-items: center;
}