.report-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.report-table {
    padding: 3px;
}

.report-diagram {
    padding: 3px;
    text-align: center;
}

.report-paragraph {
    padding: 10px;
}

.report-diagram-container div {
    margin-left: auto;
    margin-right: auto;
}

.report-table-container th, td {
    border: 1px solid rgba(224, 224, 224, 1);
}

.report-table h4 {
    text-align: center;
}

.report-chart {
    height: 800px;
}

.report-diagram-human-container {
    background: #2C343C;
    display: grid;
    grid-template-columns: 30px min-content 1fr 30px;
    grid-template-rows: 30px max-content max-content max-content 30px;
    grid-template-areas: ". . . ." ". human . ." ". human human-table ." ". human . ." ". . . .";
}

.report-diagram-human {
    background: white;
    grid-area: human;
    width: 220px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.report-diagram-human-filler {
    width: 100%;
    height: 0;
    background-color: red;
    grid-column: 1;
    grid-row: 1;
    align-self: end;
}

.report-diagram-human img {
    width: 220px;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    margin-top: auto;
}

.report-diagram-human p {
    grid-column: 1;
    grid-row: 1;
    color: black;
    position: relative;
    top: 20%;
}

.report-diagram-human-table {
    background: white;
    grid-area: human-table;
}

.report-pdf-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

#report-diagram-paper-productivity {
    /*background: black;*/
}

#report-diagram-paper-productivity h1 {
    /*color: white;*/
}

.report-diagram-multiple-human-container {
    background: #2C343C;
    width: 1280px;
    height: 800px;
    display: grid;
    grid-template-columns: 30px 1fr min-content 1fr 30px;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . . ." ". current . opt ." ". multiple-human-broken plus multiple-human-works ." ". . . . .";
}

.report-diagram-multiple-human-container p {
    color: white;
}

.multiple-human-current {
    grid-area: current;
}

.multiple-human-opt {
    grid-area: opt;
}

.multiple-human-container {
    background: white;
    width: 40px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.multiple-human-filler {
    width: 100%;
    height: 0;
    background-color: red;
    grid-column: 1;
    grid-row: 1;
    align-self: end;
}

.multiple-human-broken {
    grid-area: multiple-human-broken;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content;
}

.report-diagram-multiple-human-container img {
    width: 40px;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    margin-top: auto;
}

.multiple-human-plus {
    grid-area: plus;
}

.big-sign {
    font-size: 60px;
}

.multiple-human-works {
    grid-area: multiple-human-works;
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-template-rows: min-content;
}

.half-human {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content;
}

.half-human-filler {
    width: 40px;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    background: linear-gradient(0.25turn, #2C343C 30%, white);
}
